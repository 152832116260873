// src/routes/index.js
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";

import BatchValidity from "../pages/BatchValidity";
// import DWT from '../components/dwtScan'
import Dashboard from "../pages/Dashboard";
import Interpretation from "../pages/Interpretation";
import MatchLabIds from "../pages/MatchLabIds";
import React from "react";
import SampleInformationCard from "../pages/SampleInformationCard"
import Scanner from "../pages/ScannerUpload";
import Summary from "../pages/Summary";

const App = () => {
  const s = "/dashboard/"
  const [state, setState] = React.useState("d");
  function renderPage () {
    if (state === "u") {
      return <Scanner setState={setState} />
    } else
    if (state === "m") { return <MatchLabIds setState={setState}/> } else
    if (state === "b") { return <BatchValidity setState={setState}/> } else
    if (state === "i") { return <Interpretation setState={setState}/> } else
    if (state === "t") { return <SampleInformationCard setState={setState}/> } else
    if (state === "s") { return <Summary state={state} setState={setState}/> } else 
    if (state === "f") { return <Summary state={state} setState={setState}/> } else {
    var pageRoutes = Array.from(Array(30).keys()).map((i) => (
        <Route exact path={s.concat(i + 1)} key={i + 1}>
        <Dashboard userId = {i + 1} demo={false} setState={setState} />
        </Route>
        ));
    pageRoutes.push(
      <Route exact path={s.concat("demo")} key={"demo"}>
        <Dashboard userId ={15} demo={true} setState={setState} />
      </Route>
    )
        // pageRoutes.push(
        //   <Route exact path='/testing' key='testing'>
        //     <Summary state={state} setState={setState} />
        //   </Route>
        // )
      return pageRoutes;
    }
    
  }
  
 return (
    <Router>
      <Switch>
      {renderPage()}
      </Switch>
    </Router>
  );
}
export default App;
