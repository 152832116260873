/* Batch Related */
export const SET_USER_ID = "SET_USER_ID"
export const SET_BATCH_ID = "SET_BATCH_ID"
export const SET_QUALITY_CHECK = "SET_QUALITY_CHECK"
export const DELETE_SHEET = "DELETE_SHEET"
export const SET_UPLOADED_SHEETS = "SET_UPLOADED_SHEETS"
export const SET_BATCH_ROI = "SET_BATCH_ROI"
export const SET_INTERPRETATION = "SET_INTERPRETATION"
export const SET_QUALITY_CHECK_ERROR = "SET_QUALITY_CHECK_ERROR"
export const SET_BATCH_VALIDITY = "SET_BATCH_VALIDITY"
export const SET_SUMMARY = "SET_SUMMARY"
export const SET_BATCH_INFO_FOR_DASHBOARD = "SET_BATCH_INFO_FOR_DASHBOARD"
export const SET_USER_INFO = "SET_USER_INFO"
export const SET_DEMO = "SET_DEMO"
