import { makeStyles, withStyles } from '@material-ui/core/styles';
import { getInterpretationData, getBatchValidity } from "../actions/global"

import AppBar from "../components/appBar"
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import BreadCrumbList from '../components/breadCrumbList';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import GetAppIcon from '@material-ui/icons/GetApp';
import globalUseStyle from '../theme/customComponents';
import Grid from '@material-ui/core/Grid';
import MuiAccordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import Paper from '@material-ui/core/Paper';
import React, { useEffect, useState } from 'react';
import SampleInfoTable from "../components/sampleInfoTable"
import Typography from '@material-ui/core/Typography';
import { CSVLink } from 'react-csv';
import { useDispatch, useSelector } from 'react-redux'

const Accordion = withStyles({
    root: {
        marginBottom: "10px"
    }
  })(MuiAccordion);

  const AccordionSummary = withStyles({
    root: {
      paddingLeft: "30px",
      paddingRight: "10px",
    },
  })(MuiAccordionSummary);

const useStyles = makeStyles((theme) => ({
    heading: {
        display: "flex",
        // justifyContent: "space-around",
        justifyContent: "flex-start",
    },
    button1: {
      margin: theme.spacing(1),
      color: theme.palette.getContrastText("#1967D2"),
      backgroundColor: "#1967D2",
      "&:hover": {
          backgroundColor: "#1967D2",
          "@media (hover: none)": {
            backgroundColor: "#1967D2"
          }
      }
    },
    button: {
        margin: theme.spacing(1),
        color: theme.palette.getContrastText("#2B78C2"),
        backgroundColor: "#2B78C2",
        height: "48px",
        paddingLeft: "30px",
        paddingRight: "30px",
        "&:hover": {
            backgroundColor: "#2B78C2",
            "@media (hover: none)": {
              backgroundColor: "#2B78C2"
            }
          }
    },
    printButton: {
        width: "120px",
        height: "32px",
        backgroundColor: "rgba(0, 0, 0, 0.04)",
        color: "#5F6368",
        fontSize: "12px",
        borderRadius: 50,
        textTransform: "none",
        "&:hover": {
          backgroundColor: "#1967D2",
          "@media (hover: none)": {
            backgroundColor: "#1967D2"
          }
        }
    },
    accordianSummaryText: {
        fontSize: "22px",
        fontWeight: "500",
        lineHeight: "32px",
        letterSpacing: "0.1px",
        color: "#555770"
    },
    accordianSummaryTextHeading: {
        fontSize: "22px",
        fontWeight: "500",
        lineHeight: "32px",
        letterSpacing: "0.1px",
        paddingLeft: "15px",
        paddingRight: "4px",
        color: "#555770"
    },
    accordianSummarySampleText: {
        fontSize: "15px",
        fontWeight: "400",
        lineHeight: "30px",
        letterSpacing: "0.1px",
        paddingRight: "4px",
        color: "#555770"
    }

}));

export default function Summary ({ state, setState }) {
    const globalClasses = globalUseStyle();
    const classes = useStyles();
    const dispatch = useDispatch();

    const pageListLT = ['Upload', 'Enter Nikshay IDs', 'Check controls', 'Review Interpretation', 'Summary']
    const pageListMB = ['Check controls', 'Review Interpretation', 'Summary']

    const handleAccordionChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
      };

    const batchId = useSelector(state => state.globalActionsReducer.batch_id)?.batch_id
    const demo = useSelector(state => state.globalActionsReducer.demo)

    const [expanded, setExpanded] = React.useState("");
    const [reportHeaders, setReportHeaders] = React.useState();
    const [reportData, setReportData] = React.useState();
    const summaryData = useSelector(state => state.globalActionsReducer.summary)
    const batchType = summaryData?.batch_type ? summaryData?.batch_type : 'FL'
    const [sampleList, setSampleList] = useState({})
    if (!batchId) {
    setState("d")
    }
    useEffect(() => {
        if (summaryData?.summary) {
            var newSampleList = {}
            var index = 0;
            for (const sheet of summaryData.summary) {
                for (const sample of sheet.rows) {
                    if (!(sample.strip_condition in newSampleList)) {
                        newSampleList[sample.strip_condition] = []
                    }
                    var newSample = {
                        sheet_no: index + 1,
                        sheet_id: sample.sheet_id,
                        row_id: sample.row_id,
                        strip_image_path: sample.strip_image_path,
                        interpreted_band_array: sample.interpreted_band_array,
                        reviewed_band_array: sample.reviewed_band_array,
                        lab_sample_id: sample.lab_sample_id,
                        test_validity: sample.test_validity,
                        genotypic_interpretation: sample.genotypic_interpretation,
                        phenotypic_interpretation: sample.phenotypic_interpretation,
                        band_confidence_array: sample.band_confidence_array,
                        strip_condition: sample.strip_condition,
                        position: sample.position
                    }
                    newSampleList[sample.strip_condition].push(newSample)
                }
                index += 1;
            }
            setSampleList(newSampleList)
        }
    }, [summaryData])

    useEffect(() => {
        if (batchType === 'FL') {
            setReportHeaders([
                {label: 'Nikshay ID', key: 'lab_sample_id'},
                {label: 'Sample condition', key: 'strip_condition'},
                {label: 'Resistance to Rifampicin (RpoB)', key: 'resistance_to_rifampicin'},
                {label: 'High Level Resistance to Isoniazid', key: 'high_level_resistance_to_isoniazid'},
                {label: 'Low Level Resistance to Isoniazid', key: 'low_level_resistance_to_isoniazid'},
                {label: "TUB", key: "TUB"},
                {label: "rpoB WT", key: "rpoB WT"},
                {label: "rpoB MUT", key: "rpoB MUT"},
                {label: "katG WT", key: "katG WT"},
                {label: "katG MUT", key: "katG MUT"},
                {label: "inhA WT", key: "inhA WT"},
                {label: "inhA MUT", key: "inhA MUT"},
              ])
        } 
        else {
            setReportHeaders([
                {label: 'Nikshay ID', key: 'lab_sample_id'},
                {label: 'Sample condition', key: 'strip_condition'},
                {label: 'Resistance to Fluoroquinolones: (gyr A, gyr B)', key: 'resistance_to_fluoroquinolones'},
                {label: 'Resistance to Second Line Injectable Drugs: (Km, Am and Cm) (rrs)', key: 'resistance_to_second_line_injectable_drugs_rrs'},
                {label: 'Resistance to Second Line Injectable Drugs: (Low Level Km) (eis)', key: 'resistance_to_second_line_injectable_drugs_low'},
                {label: "TUB", key: "TUB"},
                {label: "gyrA WT", key: "gyrA WT"},
                {label: "gyrA MUT", key: "gyrA MUT"},
                {label: "gyrB WT", key: "gyrB WT"},
                {label: "gyrB MUT", key: "gyrB MUT"},
                {label: "rrs WT", key: "rrs WT"},
                {label: "rrs MUT", key: "rrs MUT"},
                {label: "eis WT", key: "eis WT"},
                {label: "eis MUT", key: "eis MUT"},

              ])
        }  
    }, [batchType])

    function accordianDisplay (panelName, summaryHeading, summaryText, sampleInfo) {
        return (
        <Accordion
        elevation={1}
        expanded={expanded === panelName}
        onChange={handleAccordionChange(panelName)}>
            <AccordionSummary aria-controls={panelName + "-content"} id={panelName + "-header"}>
            {expanded === panelName ? <ArrowDropDownIcon style={{ color: "#2B78C2", fontSize: "30px" }}/> : <ArrowRightIcon style={{ color: "#2B78C2", fontSize: "30px" }}/>}
                <Typography className={classes.accordianSummaryTextHeading}>
                {summaryHeading}
                </Typography>
                <Typography className={classes.accordianSummaryText}>
                {summaryText}
                </Typography>
            <Grid style={{
                display: "flex",
                flexDirection: 'column',
                alignItems: "flex-end",
                justifyContent: "center",
                flex: 1
            }}>
            <Typography className={classes.accordianSummarySampleText}>
            ({sampleInfo?.length} samples)
            </Typography>
            </Grid>
            </AccordionSummary>
            <AccordionDetails justifyContent="center" >
            <SampleInfoTable allowEdit={state === 's'} sampleInfo={sampleInfo} testValidity={sampleInfo?.[0].test_validity} batchType={batchType} batchId={batchId} batchValidity={summaryData?.is_valid_batch} reportHeaders={reportHeaders} setReportData={setReportData} />
            </AccordionDetails>
        </Accordion>
        )
    }

    function displayResultsForFl () {
        return (
        <React.Fragment>
            {sampleList?.rif_and_inh_sensitive && accordianDisplay("panel1", "M.tb detected:", "Rif and Inh Sensitive", sampleList.rif_and_inh_sensitive) }
            {sampleList?.send_for_sl_lpa && accordianDisplay("panel2", "M.tb detected:", "Send for SL-LPA", sampleList.send_for_sl_lpa) }
            {sampleList?.invalid_repeat_fl_lpa && accordianDisplay("panel3", "Invalid:", "Repeat FL-LPA", sampleList.invalid_repeat_fl_lpa) }
            {sampleList?.send_for_culture && accordianDisplay("panel5", "M.tb not detected:", "Send for Culture", sampleList.send_for_culture) }
            {sampleList?.indeterminate_repeat_fl_lpa && accordianDisplay("panel6", "Indeterminate:", "Repeat FL-LPA", sampleList.indeterminate_repeat_fl_lpa) }
            {sampleList?.invalid_control_bands && accordianDisplay("panel7", "Invalid Control bands:", "Repeat FL-LPA", sampleList.invalid_control_bands) }
        </React.Fragment>
        )
    }
    function displayResultsForSl () {
        return (
        <React.Fragment>
            {sampleList?.all_sensitive && accordianDisplay("panel1", "M.tb detected:", "All Resistance not detected", sampleList.all_sensitive) }
            {sampleList?.flq_sensitive_sli_resistant && accordianDisplay("panel2", "M.tb detected:", "FLQ sensitive, SLI resistant", sampleList.flq_sensitive_sli_resistant) }
            {sampleList?.flq_resistant_sli_sensitive && accordianDisplay("panel3", "M.tb detected:", "FLQ resistant, SLI sensitive", sampleList.flq_resistant_sli_sensitive) }
            {sampleList?.flq_resistant_sli_resistant && accordianDisplay("panel4", "M.tb detected:", "FLQ resistant, SLI resistant", sampleList.flq_resistant_sli_resistant) }
            {sampleList?.invalid_repeat_sl_lpa && accordianDisplay("panel5", "Invalid:", "Repeat SL-LPA", sampleList.invalid_repeat_sl_lpa) }
            {sampleList?.indeterminate_repeat_sl_lpa && accordianDisplay("panel6", "Indeterminate:", "Repeat SL-LPA", sampleList.indeterminate_repeat_sl_lpa) }
            {sampleList?.send_for_culture && accordianDisplay("panel7", "M.tb not detected:", "Send for Culture", sampleList.send_for_culture) }
            {sampleList?.invalid_control_bands && accordianDisplay("panel8", "Invalid Control bands:", "Repeat SL-LPA", sampleList.send_for_culture) }
        </React.Fragment>
        )
    }

    function handleBack () {
        if (summaryData?.is_valid_batch) {
          dispatch(getInterpretationData(batchId)).then(() => {
            setState("i")
        })
        } else {
          dispatch(getBatchValidity(batchId)).then(() => {
            setState('b')
        })
        }
    }

    function handleNext () {
        if (state === 's' && !demo) { setState('t') } else { setState('d') }
    }

    return (
        <React.Fragment>
          <CssBaseline />
          <AppBar />
          <Grid container justifyContent="center" direction="row" style={{ backgroundColor: "white" }}>
            <BreadCrumbList pageList={summaryData?.mb_review ? pageListMB : pageListLT} setState={setState} warningMessage={null} />
              <Grid item lg={7} style={{ paddingLeft: "50px" }}>
                  <Paper elevation={0} style={{ borderRadius: "6px", padding: "30px" }}>
                    <div className={classes.heading}>
                        {/* <ArrowBackIcon style={{ color: "#1A73E8", cursor: "pointer" }} onClick={() => history.push("/interpretation")}/> */}
                        <div style={{flexGrow: 1, display: "flex", justifyContent: "flex-start"}}>
                            {
                            state === 's' &&
                            <ArrowBackIcon onClick={handleBack} style={{ color: "#2B78C2", cursor: "pointer" }}></ArrowBackIcon>
                            }
                            <Typography style={{ color: "#202124", fontSize: "32px", fontWeight: "400", lineHeight: "24px", letterSpacing: "0.1px", alignItems: "center", paddingLeft: "15px" }} gutterBottom>
                            Summary
                            </Typography>
                        </div>
                        
                        <div>
                        {reportData?.length &&
                            <CSVLink
                                headers={reportHeaders}
                                data={reportData}
                                filename={new Date().toLocaleString().replace(",","") + "_summary.csv"}
                                target="_blank"
                            >
                                <Button className={globalClasses.outlinedButton}
                                    onClick={() => {}}
                                    variant="outlined" component="label" startIcon={<GetAppIcon></GetAppIcon>}>
                                    Download
                                </Button>
                            </CSVLink>
                        }
                        </div>
                    </div>
                    {/* <div style={{ display: "flex", alignItems: "center", paddingTop: "30px", justifyContent: "space-between" }}>
                        <Typography style={{ fontSize: "18px", fontWeight: "700", lineHeight: "24px", letterSpacing: "0.1px", color: "#202124" }}>
                        Workflow recommendations
                        </Typography>
                    </div> */}
                        <Typography style={{ marginLeft: (state === 's' ? "40px": "15px"), fontSize: "14px", fontWeight: "500", lineHeight: "22px", letterSpacing: "0.1px", color: summaryData?.is_valid_batch ? '#48D292' : '#FF5757' }}>{summaryData?.is_valid_batch ? 'Valid' : 'Invalid'} Batch</Typography>

                        <div style={{ paddingTop: "20px" }}>
                          {batchType === 'FL' ? displayResultsForFl() : displayResultsForSl()}
                        </div>

                        <div style={{ display: "flex", justifyContent: "center", paddingTop: "20px" }}>
                        <Button onClick={handleNext} className={classes.button} endIcon={<ArrowForwardIcon></ArrowForwardIcon>}>
                        {/* <Button onClick={handleNext} style={{
                                    width: "250px",
                                    height: "40px",
                                    color: "#FFFFFF",
                                    fontSize: "16px",
                                    backgroundColor: "#1967D2",
                                    borderRadius: 50,
                                    "&:hover": {
                                        backgroundColor: "#1967D2",
                                        "@media (hover: none)": {
                                            backgroundColor: "#1967D2"
                                        }
                                        }}}> */}
                            { state === 's' && !demo
                            ? "Enter sample information"
                            : "Return to Dashboard"
                            }
                        </Button>
                        </div>
                  </Paper>
              </Grid>
          </Grid>

        </React.Fragment>
      );
}
