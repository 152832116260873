import AppBar from "@material-ui/core/AppBar";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Button from "@material-ui/core/Button";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import Grid from "@material-ui/core/Grid";
import globalColors from "../theme/colors";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import PersonIcon from "@material-ui/icons/Person";
import React from "react";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";

export default function MenuAppBar ({ userInfo }) {
  const userInformation = userInfo || {
    name: window.sessionStorage.getItem("name"),
    designation: window.sessionStorage.getItem("designation"),
    lab_name: window.sessionStorage.getItem("lab_name"),
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <React.Fragment>
      <AppBar
        position="relative"
        elevation={4}
        style={{
          background: "white",
          height: "80px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Toolbar>
          {/* <Typography
            style={{ color: "#202124", fontSize: "45px", fontWeight: "900", lineHeight: "38px", letterSpacing: "-1px" }}>
            LIMS
          </Typography> */}
          <img src="/nav_logo.svg" alt="Logo" style={{paddingLeft: "20px"}} />
          <Grid
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-end",
              justifyContent: "right",
              flex: 1,
            }}
          >
            <Typography
              style={{
                color: globalColors.textColor2,
                fontSize: "14px",
                fontWeight: "500",
                lineHeight: "18px",
                letterSpacing: "0.1px",
                padding: 15,
              }}
            >
              CALL 18001166666 FOR HELP
            </Typography>

            <Typography
              style={{
                color: globalColors.textColor2,
                fontSize: "14px",
                fontWeight: "500",
                lineHeight: "18px",
                letterSpacing: "0.1px",
                padding: 15,
              }}
            >
              ASK FOR HELP
            </Typography>
            <Button
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
              style={{
                padding: 15,
              }}
            >
              <PersonIcon color={globalColors.textColor2}/>
              <Typography
                style={{
                  color: globalColors.textColor2,
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "18px",
                  letterSpacing: "0.1px",
                }}
              >
                {userInformation.name}
              </Typography>
              <ArrowDropDownIcon color={globalColors.textColor2}/>
            </Button>
            {/* <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem onClick={handleClose}>
                <ExitToAppIcon />
                Logout
              </MenuItem>
            </Menu> */}
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
}
