import { InterpretationViewType, ScreenNames, SortingOrder } from "../constants/CustomEnums";
import React, { useEffect, useState } from 'react';
import { getBatchValidity, getInterpretation, submitInterpretation } from "../actions/global"
import {
  logClickOnSortOptionEvent,
  logInterpretationViewTypeClickEvent,
  logPreviewSheetEvent
} from "../analytics/analyticsHelper";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux'

import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AppBar from "../components/appBar"
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import BreadCrumbList from "../components/breadCrumbList";
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import globalUseStyle from "../theme/customComponents";
import Grid from '@material-ui/core/Grid';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import IconButton from '@material-ui/core/IconButton';
import ImageCarousel from '../components/carousel'
import InterpretationTable from '../components/interpretationTable';
import { InterpretationTipsComponent } from "../components/tips/InterpretationTipsComponent";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from '@material-ui/core/Paper';
import SaveWarning from '../components/saveWarning';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import TextField from "@material-ui/core/TextField";
import Typography from '@material-ui/core/Typography';
import { useBreakpoint } from "../utils";
import globalColors from "../theme/colors";

// import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    leftPaper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
        height: 400,
      },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
        height: 650,
      },
      container: {
        paddingLeft: 40,
        paddingRight: 40,
      },
      dashboardContainer: {
        paddingLeft: 20,
      },
      nextStepButton: {
          display: "flex",
          paddingTop: 300,
          flexDirection: "column",
          alignItems: "center"
      },
      rightSideDiv: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: 280
    },
    c1: {
        paddingTop: 5
    },
    heading: {
        display: "flex",
        justifyContent: "flex-start",
        paddingTop: "30px",
        paddingLeft: "10px"
    },
    button: {
      margin: theme.spacing(1),
      color: theme.palette.getContrastText("#1967D2"),
      backgroundColor: "#1967D2",
      fontWeight: "500",
      fontStyle: "normal",
      fontSize: "14px",
      lineHeight: "18px",
      letterSpacing: "0.1px",
      "&:hover": {
          backgroundColor: "#1967D2",
          "@media (hover: none)": {
            backgroundColor: "#1967D2"
          }
        }
  }
}));

const AntTabs = withStyles({
  indicator: {
    backgroundColor: "#1A73E8"
  }
})(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    border: '1px solid #2B78C2',
    textTransform: "none",
    "&:hover": {
      color: "#1A73E8",
      opacity: 1
    },
    "&$selected": {
      backgroundColor: "#E5EDFF",
      color: "#1A73E8",
      fontWeight: theme.typography.fontWeightMedium
    },
    "&:focus": {
      color: "#1A73E8"
    },
    minWidth: "407px"
  },
  selected: {}
}))((props) => <Tab disableRipple {...props} />);

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#DADCE0"
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#DADCE0",
      borderWidth: "1px"
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#DADCE0"
      },
      "&:hover fieldset": {
        borderColor: "#DADCE0",
        borderWidth: "1px"
      },
      "&.Mui-focused fieldset": {
        borderColor: "#DADCE0",
        borderWidth: "1px"
      }
    }
  }
})(TextField);

const Interpretation = ({ setState }) => {
  // if score == 0 then review is not needed
  const dispatch = useDispatch();
  const classes = useStyles();
  const globalClasses = globalUseStyle();
  const breakpoint = useBreakpoint()
  // const history = useHistory();

  const pageListLT = ['Upload', 'Enter Nikshay IDs', 'Check controls', 'Review Interpretation']
  const pageListMB = ['Check controls', 'Review Interpretation']

  const [showBackDialog, setShowBackDialog] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const [expanded, setExpanded] = React.useState(false);
  const [imagePreview, setImagePreview] = React.useState(false);
  const handleAccordionChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const batchId = useSelector(state => state.globalActionsReducer.batch_id)?.batch_id

  // const batchId = 15;
  // const batchId = 179;
  useEffect(() => {
    dispatch(getInterpretation(batchId, true, ""));
  }, [batchId, dispatch])

  if (!batchId) {
    setState("d")
    // history.push("/dashboard")
  }
  const interpretationApiData = useSelector(state => state.globalActionsReducer.interpretation)
  const [interpretationResults, setInterpretationResults] = useState([])
  const [batchType, setBatchType] = useState("")
  const [modifiedInputArray, setModifiedInputArray] = useState([])
  const [modifiedOutputArray, setModifiedOutputArray] = useState([])
  const [mbReview, setMbReview] = useState(false)

  const filterForNotReview = (arr) => {
    return arr.filter(el => (el.score === 0 && el.isFlagged === false))
  }
  const filterForReview = (arr) => {
    return arr.filter(el => (el.score > 0 || el.isFlagged === true))
  }
  const filterOnSheetId = (arr, sheetId) => {
    return arr.filter(el => el.sheetId === sheetId)
  }

  useEffect(() => {
    setInterpretationResults(interpretationApiData?.sheets ? interpretationApiData?.sheets : [])
    setBatchType(interpretationApiData?.batch_type ? interpretationApiData?.batch_type : 'FL')
    setMbReview(interpretationApiData?.mb_review ? interpretationApiData?.mb_review : false)
  }, [interpretationApiData])

  useEffect(() => {
    const inputArray = []
    const outputArray = []

    const combineBandArrays = (interpreted, confidence) => {
      const bandData = []
      interpreted.forEach((obj, i) => {
        bandData.push({
          bandPresent: obj === 1,
          confidence: confidence[i]
        })
      })
      return bandData
    }

    const combineResultArrays = (validity, genotypic, phenotypic) => {
      const resultData = [] // 11, 14
      const size = batchType === 'FL' ? 11 : 14;
      if (validity === 'INVALID_TEST') {
        resultData.push('INVALID TEST')
      } else if (validity === 'INDETERMINATE_TEST') {
        resultData.push('INDETERMINATE TEST')
      } else if (validity === 'MTB_NOT_DETECTED') {
        resultData.push('MTB NOT DETECTED')
      }
      if (resultData[0] === 'INVALID TEST' || resultData[0] === 'INDETERMINATE TEST' || resultData[0] === 'MTB NOT DETECTED') {
        [...Array(size - 1)].map(() =>
          resultData.push('')
        )
        return resultData
      } else {
        if (batchType === 'FL') {
          genotypic.map(obj => {
            resultData.push(obj)
            return true
          })
          phenotypic.map(obj => {
            if (obj === 'S') {
              resultData.push('S')
              resultData.push('')
            } else {
              resultData.push('')
              resultData.push('R')
            }
            return true
          })
        } else {
          genotypic.map(obj => {
            resultData.push(obj)
            return true
          })
          resultData.push(phenotypic[0])
          resultData.push(phenotypic[1])
          resultData.push('')
          resultData.push('')
          resultData.push(phenotypic[2])
        }
        return resultData
      }
    }

    interpretationResults.forEach(function (sheetObject, index) {
      sheetObject.rows.forEach(rowObject => {
        if (!rowObject.is_empty) {
          var resultArray = combineResultArrays(rowObject.test_validity, rowObject.genotypic_interpretation, rowObject.phenotypic_interpretation)
          var isResistant = resultArray.reduce((prev, curr) => prev || curr === 'R', false )
          inputArray.push({
            sheetNo: index + 1,
            sheetId: sheetObject.sheet_id,
            rowId: rowObject?.row_id,
            labSampleId: rowObject?.lab_sample_id,
            position: rowObject.position,
            score: rowObject?.score,
            isFlagged: rowObject?.is_flagged,
            isReported: rowObject?.is_reported,
            sampleRetest: rowObject.sample_retest,
            stripImagePath: rowObject.strip_image_path,
            bandData: combineBandArrays(rowObject?.reviewed_band_array ? rowObject.reviewed_band_array : rowObject.interpreted_band_array,
              rowObject.band_confidence_array),
            isResistant: isResistant
          })

          outputArray.push({
            sheetId: sheetObject.sheet_id,
            rowId: rowObject?.row_id,
            position: rowObject.position,
            score: rowObject?.score,
            resultData: resultArray,
            isResistant: isResistant,
            isFlagged: rowObject?.is_flagged,
          })
        }
      })
    })
    setExpanded( filterForReview(inputArray)?.length > 0 ? "panel1": "panel2")
    setModifiedInputArray([...inputArray])
    setModifiedOutputArray([...outputArray])
  }, [batchType, interpretationResults])

  const [sortOrder, setSortOrder] = useState(0)
  useEffect(() => {
    const inputArray = [...modifiedInputArray]
    const outputArray = [...modifiedOutputArray]

    inputArray.sort((input1, input2) => {
      if (sortOrder === 0) {
        if (input1.position < input2.position) {
          return -1
        }
        return 1
      } 
      else if (sortOrder === 1) {
        if ( (input1.isResistant && input2.isResistant) || (!input1.isResistant && !input2.isResistant) )
        {
          if (input1.position < input2.position) {
            return -1
          }
          return 1 
        }
        else if (input1.isResistant) { return -1 } else { return 1 } 

      }
      else {
        if (mbReview) {
          if (input1.isFlagged) { return -1 } else if (input2.isFlagged) { return 1 } else { return -1 }
        } else {
          if (input1.score > input2.score) {
            return -1
          }
          return 1
        }
      }
    })
    outputArray.sort((input1, input2) => {
      if (sortOrder === 0) {
        if (input1.position < input2.position) {
          return -1
        }
        return 1
      } else if (sortOrder === 1) {
        if ( (input1.isResistant && input2.isResistant) || (!input1.isResistant && !input2.isResistant) )
        {
          if (input1.position < input2.position) {
            return -1
          }
          return 1 
        }
        else if (input1.isResistant) { return -1 } else { return 1 }
      } else {
        if (mbReview) {
          if (input1.isFlagged) { return -1 } else if (input2.isFlagged) { return 1 } else { return -1 }
        } else {
          if (input1.score > input2.score) {
            return -1
          }
          return 1
        }
      }
    })
    setModifiedInput([...inputArray])
    setModifiedOutput([...outputArray])
  }, [sortOrder, modifiedInputArray, modifiedOutputArray, mbReview])

  const [modifiedInput, setModifiedInput] = React.useState([])
  const [modifiedOutput, setModifiedOutput] = React.useState([])
  const [selectedSheetId, setSelectedSheetId] = React.useState(interpretationResults.length ? interpretationResults[0]?.sheet_id : '-1')

  useEffect(() => {
    setSelectedSheetId(interpretationResults.length ? interpretationResults[0]?.sheet_id : '-1')
  }, [interpretationResults])

  function convertToArray (arr) {
      const output = []
      arr.map(i => {
        output.push(i.bandPresent ? 1 : 0)
        return true
      })
      return output
  }
  function onImageClick (sheetId) {
    setSelectedSheetId(sheetId)
  }
  function onSummaryClick () {
    const updatedRows = []
    modifiedInput.map(obj => {
      updatedRows.push({
        row_id: obj.rowId,
        reviewed_band_array: convertToArray(obj.bandData)
      })
      return true
    })
    dispatch(submitInterpretation(batchId, updatedRows)).then(() => { setState("s") })
  }

  const handleChange = (event, newValue) => {
    logInterpretationViewTypeClickEvent(newValue === 0 ? InterpretationViewType.PRIORITISATION_VIEW : InterpretationViewType.SHEET_VIEW)
    setValue(newValue);
  };

  const [selectedImageIndexForPreview, setSelectedImageIndexForPreview] = useState(0)
  const handleImagePreviewOpen = (index) => {
    logPreviewSheetEvent(ScreenNames.INTERPRETATION)
    setImagePreview(true);
    setSelectedImageIndexForPreview(index)
  };

  const handleImagePreviewClose = () => {
    setImagePreview(false);
  };

  const handleSortOrderChange = (event) => {
    setSortOrder(event.target.value)
    logClickOnSortOptionEvent(event.target.value === 0 ? SortingOrder.ASCENDING : event.target.value === 1 ? SortingOrder.DESCENDING : SortingOrder.STRIPS_TO_REVIEW_FIRST)
  }

  const createTabHeader = (header = "Prioritisation View", description = "Focus on strips where the algorithm is not sure") => {
    return (
      <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", fontSize: "16px", alignContent: "flex-start", fontWeight: "500" }}>
        <div>{header}</div>
        <span style={{ fontSize: "12px", color: "#202124", textAlign: "center", fontWeight: "400", lineHeight: "1.2" }}>{description}</span>
      </div>
    )
  }

  function handleBack () {
    dispatch(getBatchValidity(batchId)).then(() => { setState('b') })
  }
  const [showHelpState, setshowHelpState] = React.useState(true);
  function handleShowHelp () {
    setshowHelpState(!showHelpState)
  }

  return (
    <div>
      <CssBaseline />
      <AppBar />
      <SaveWarning changePage={handleBack} warningMessage={null} showDialog={showBackDialog} setShowDialog={setShowBackDialog}/>
      <InterpretationTipsComponent key={showHelpState}/>
      {imagePreview && <ImageCarousel
        open={imagePreview}
        onClose={handleImagePreviewClose}
        images={interpretationResults.map(row => row.transformed_image_location)}
        index={selectedImageIndexForPreview}
      />}
      <Grid container className={classes.dashboardContainer} style={{ background: "white" }}>
      <BreadCrumbList pageList={mbReview ? pageListMB : pageListLT} setState={setState} warningMessage={null} />
        <Paper elevation={0} style={{ width: "100%", height: value === 1 ? "400px" : "150px", backgroundColor: "white", marginBottom: "20px"}}>
            <div className={classes.heading}>
              {/* <ArrowBackIcon style={{ color: "#1A73E8", cursor: "pointer" }} onClick={() => setState("v")}/> */}
              <ArrowBackIcon onClick={() => setShowBackDialog(true)} style={{ color: "#1A73E8", cursor: "pointer" }}></ArrowBackIcon>
              <Typography style={{ paddingLeft: "15px", fontSize: "32px", fontWeight: "400", lineHeight: "24px", letterSpacing: "0.1px", fontStyle: "normal"}} gutterBottom>
              Review Interpretation
              </Typography>
              <HelpOutlineIcon onClick={handleShowHelp} style={{ marginLeft: "5px", cursor: "pointer" }}></HelpOutlineIcon>
            </div>
            <div style={{ paddingLeft: "30px" }}>
              <Grid container>
                <Grid item xs = {12}>
                  <AntTabs value={value} indicatorColor="primary" textColor="primary" onChange={handleChange} centered>
                    <AntTab label={createTabHeader("Prioritization View", "Focus on samples where the algorithm is not sure")} style={{ borderBottomLeftRadius: "8px", borderTopLeftRadius: "8px" }} />
                    <AntTab label={createTabHeader("Sheet View", "See all your samples in order of sheet")} style={{ borderBottomRightRadius: "8px", borderTopRightRadius: "8px" }} />
                  </AntTabs>
                </Grid>
              </Grid>
            </div>
            {value === 1
              ? <Grid container style={{ display: "flex", justifyContent: "center", paddingTop: "20px", paddingBottom: "60px" }} spacing={2}>
                {interpretationResults.map((i, j) => (
                    <Grid item style={{ width: "140px", height: "200px", alignItems: "center", display: "flex", flexDirection: "column" }}>
                      <div style={{ height: "150px" }}>
                      <img onClick={() => onImageClick(i.sheet_id)} alt="scale" style={{ width: "100%", height: "100%", border: "1px solid #BDC1C6", borderRadius: "3px", cursor: "pointer" }} src={i.transformed_image_location}/>
                      {selectedSheetId === i.sheet_id && <Paper variant="outlined" style={{ backgroundColor: "#2B78C2", height: "8px" }}/>}
                      </div>
                      <Typography style={{ paddingTop: "15px", color: "#3C4043", fontSize: "14px", fontWeight: "400", lineHeight: "22px", letterSpacing: "0.1px" }}>
                          {i.rows.length} strips
                      </Typography>
                      <Typography style={{ color: "orange", fontSize: "14px", fontWeight: "bold", lineHeight: "22px", letterSpacing: "0.1px" }}>
                        (Review {i.rows.filter(row => row.score > 0).length} Strip)
                      </Typography>
                      <Typography style={{ color: "#80868B", fontSize: "12px", fontWeight: "400", lineHeight: "22px", letterSpacing: "0.1px", textDecorationLine: "underline", cursor: 'pointer' }} onClick={() => handleImagePreviewOpen(j)}>
                        View Sheet
                      </Typography>
                    </Grid>
                ))}

              </Grid>
              : ''
              }
              {value === 1 && 
              <Grid container>
                <Grid item xs={8} md={8} lg={8}></Grid>
                  <Grid item xs={3} md={3} lg={3} style={{display: "flex", justifyContent: "center"}}>
                    <Grid item xs={6} md={6} lg={6}>
                        <div style={{marginLeft: "10px"}}>
                        <Typography style={{ fontSize: "14px", color: globalColors.textColor2, fontWeight: "400", lineHeight: "24px", letterSpacing: "0.1px"}}>
                        Sort samples by
                        </Typography>
                        </div>
                            <CssTextField
                              select
                              size="small"
                              variant="outlined"
                              // label=""
                              value={sortOrder}
                              onChange={e => handleSortOrderChange(e)}
                              style={{ width: "204px", margin: 8 }}
                            >
                              <MenuItem key={0} value={0}>Sheet order</MenuItem>
                              <MenuItem key={1} value={1}>Resistant samples first</MenuItem>
                              {
                                mbReview
                                ? <MenuItem key={2} value={2}>Samples where lab technician is not sure first</MenuItem>
                                : <MenuItem key={2} value={2}>Samples where algorithm is not sure first</MenuItem>
                              }
                            </CssTextField>
                    </Grid>
                  </Grid>
                </Grid>
              }
        </Paper>

        {value === 0
          ? <Grid container style={{ justifyContent: breakpoint === "lg" ? "center": "start" , paddingTop: "10px" }}>
                <Accordion id="1" elevation={0} square expanded={expanded === "panel1"} onChange={handleAccordionChange("panel1")} >
                  <AccordionSummary >
                    <div style={{ paddingLeft: "20px" }}> </div>
                    { expanded === "panel1" ? <ArrowDropDownIcon/> : <ArrowRightIcon/>}
                    <Typography style={{ fontSize: "24px", fontWeight: "400", lineHeight: "32px", letterSpacing: "0.1px", fontStyle: "normal" }}>{mbReview ? "Samples prioritized for review" : "Samples where algorithm is not sure"} ({filterForReview(modifiedInput).length})</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                  <div style={{ paddingBottom: "20px", paddingTop: "20px", borderTop: "1px solid #E8EAED" }}>
                    <InterpretationTable batchType={batchType} input={modifiedInputArray} output={modifiedOutputArray} setInput={setModifiedInputArray} setOutput={setModifiedOutputArray} inputToDisplay={filterForReview(modifiedInputArray) } outputToDisplay={filterForReview(modifiedOutputArray) }/>
                  </div>
                  </AccordionDetails>
                </Accordion>
                <Accordion id="2" elevation={0} square expanded={expanded === "panel2"} onChange={handleAccordionChange("panel2")} >
                  <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                    <div style={{ paddingLeft: "20px" }}> </div>
                    {expanded === "panel2" ? <ArrowDropDownIcon/> : <ArrowRightIcon/>}
                    <Typography style={{ fontSize: "24px", fontWeight: "400", lineHeight: "32px", letterSpacing: "0.1px", fontStyle: "normal" }}>{mbReview ? "Samples where algorithm and lab technician have high confidence" : "Samples where algorithm has high confidence"}({filterForNotReview(modifiedInput).length })</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                  <div style={{ paddingBottom: "20px", paddingTop: "20px", borderTop: "1px solid #E8EAED" }}>
                  <InterpretationTable batchType={batchType} input={modifiedInputArray} output={modifiedOutputArray} setInput={setModifiedInputArray} setOutput={setModifiedOutputArray} inputToDisplay={filterForNotReview(modifiedInputArray) } outputToDisplay={filterForNotReview(modifiedOutputArray) }/>
                  </div>
                  </AccordionDetails>
                </Accordion>
              <Grid container direction="row" display="flex" justify="center" style={{ marginTop: "30px", marginBottom: "30px" }}>
              <Button className={globalClasses.blueButton} variant="contained" onClick={onSummaryClick} endIcon={<ArrowForwardIcon></ArrowForwardIcon>}>Interpretation Summary</Button>
              {/* <Button variant="contained" onClick={onSummaryClick} style={{
                                width: "200px",
                                height: "40px",
                                fontSize: "16px",
                                color: "#FFFFFF",
                                backgroundColor: "#1967D2",
                                borderRadius: "100px",
                                "&:hover": {
                                    backgroundColor: "#1967D2",
                                    "@media (hover: none)": {
                                        backgroundColor: "#1967D2"
                                    }
                                    }
                            }}>Interpretation Summary</Button> */}
                {/* <Button onClick={handleBack} className={classes.button}
                      variant="contained" endIcon={<ArrowBackIcon></ArrowBackIcon>}>
                Previous
              </Button> */}
              </Grid>
          </Grid>
          : <Grid container style={{ justifyContent: "center" }}>
              <div style={{ paddingBottom: "20px", paddingTop: "20px" }}>
              <InterpretationTable batchType={batchType} input={modifiedInput} output={modifiedOutput} setInput={setModifiedInput} setOutput={setModifiedOutput} inputToDisplay={filterOnSheetId(modifiedInput, selectedSheetId) } outputToDisplay={filterOnSheetId(modifiedOutput, selectedSheetId) }/>
              </div>

              <Grid container direction="row" display="flex" justify="center" style={{ marginTop: "30px", marginBottom: "30px" }}>
                <Button className={globalClasses.blueButton} variant="contained" onClick={onSummaryClick}>Interpretation Summary</Button>
                {interpretationResults.length > 1
                ? <React.Fragment>

                <IconButton disabled={interpretationResults.findIndex(obj => obj.sheet_id === selectedSheetId) <= 0}
                onClick= {() => setSelectedSheetId(interpretationResults[interpretationResults.findIndex(obj => obj.sheet_id === selectedSheetId) - 1].sheet_id)}
                className={classes.button} variant="contained">
                  <ArrowBackIcon/>
                </IconButton>
                <IconButton disabled={selectedSheetId === -1 || interpretationResults.findIndex(obj => obj.sheet_id === selectedSheetId) >= interpretationResults.length - 1}
                onClick= {() => setSelectedSheetId(interpretationResults[interpretationResults.findIndex(obj => obj.sheet_id === selectedSheetId) + 1].sheet_id)}
                className={classes.button} variant="contained" >
                  <ArrowForwardIcon/>
                </IconButton> </React.Fragment> : ''}
                {/* <Button className={classes.button} variant="contained" endIcon={<ArrowRightAltOutlinedIcon></ArrowRightAltOutlinedIcon>}> Next Sheet</Button> */}
              </Grid>
          </Grid>
        }

      </Grid>

    </div>
  );
}

export default Interpretation;
