import { makeStyles, withStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Divider from '@material-ui/core/Divider';
import Grid from "@material-ui/core/Grid";
import globalColors from '../theme/colors';
import globalUseStyle from '../theme/customComponents';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import React from 'react';
// import { Text } from "react-native";
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { useDispatch } from 'react-redux';


const CssTextField = withStyles({
    root: {
      "& label.Mui-focused": {
        color: "#BDC1C6"
      },
      "& .MuiInput-underline:after": {
        borderBottomColor: "#BDC1C6",
        borderWidth: "1px"
      },
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: "#BDC1C6"
        },
        "&:hover fieldset": {
          borderColor: "#BDC1C6",
          borderWidth: "1px"
        },
        "&.Mui-focused fieldset": {
          borderColor: "#BDC1C6",
          borderWidth: "1px"
        }
      }
    }
  })(TextField);

const SelectLPATypeDialog = ({ selectLPADialog, setState, setSelectLPADialog = () => {}, setSelectLPATypeState = () => {} }) => {
    // const dispatch = useDispatch();
    const globalClasses = globalUseStyle();
    const [selectLPAOption, setSelectLPAOption] = React.useState(-1);
    // const [lpaType, setLPAType] = React.useState(0);
    // function callLPASelect () {
    //     setLPATypeDialog(false)
    // }
    function callSelectLPACancel () {
        setSelectLPADialog(false);
        setState("d");
    }
    function callSelectLPASelected () {
        setSelectLPATypeState(selectLPAOption);
        setSelectLPADialog(false);
    }

  return (
    <React.Fragment>
        <Dialog open= {selectLPADialog} PaperProps={{style: {backgroundColor: globalColors.backgroundColor2, boxShadow: 'none'}}}>
            {/* <Paper elevation={0} style={{  }}> */}
            <Grid style={{display: "flex", marginTop: "28px", marginLeft: "24px", flexDirection: "column", width: "546.5px", height: "272px"}}>
            <Typography style={{ fontSize: "28px", fontWeight: "400", lineHeight: "18px", letterSpacing: "0.1px", alignItems: "center", paddingBottom: "8px"}} gutterBottom>
                Select LPA Type
            </Typography>
            <Typography style={{ fontSize: "14px", fontWeight: "400", lineHeight: "24px", letterSpacing: "0.1px", alignItems: "center", paddingTop: "25px", color: "#555770" }} gutterBottom>
                LPA Type
            </Typography>
            <CssTextField
            select
            size="small"
            variant="outlined"
            // value={selectLPAOption}
            // defaultValue={0}
            displayEmpty
            label={"Select LPA type"}
            onChange={(event) => { setSelectLPAOption(event.target.value) }}
            style={{ width: "301px", height: "40px", color: "black", background: globalColors.backgroundColor1 }}
            margin="dense"
            SelectProps={{
                displayEmpty: true
            }}
            InputLabelProps={{
                      style: { color: globalColors.textColor3, fontSize: "14px", fontWeight: "400px", letterSpacing: "0.1px", },
                      }}
            >
                <MenuItem key={0} value={0}>FL </MenuItem>
                <MenuItem key={1} value={1}>SL</MenuItem>
            </CssTextField>
            <Divider variant="middle" style={{ marginTop: "28px", marginBottom: "28px" }}/>
            <DialogActions>
                <Button variant="outlined" className={globalClasses.cancelButton} onClick={callSelectLPACancel}>Cancel</Button>
                <Button variant="contained" className={globalClasses.dialogButton} onClick={callSelectLPASelected} disabled={selectLPAOption === -1}>Upload Sheets</Button>
            </DialogActions>
            {/* </Paper> */}
            </Grid>
        </Dialog>
    </React.Fragment>
  );
}

export default SelectLPATypeDialog;
