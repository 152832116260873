import React, { useEffect } from 'react';
import { getBatchRoi, getInterpretation, getSummary } from "../actions/global"
import { useDispatch, useSelector } from 'react-redux'

import AppBar from "../components/appBar"
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import BreadCrumbList from '../components/breadCrumbList';
import Button from '@material-ui/core/Button';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CssBaseline from '@material-ui/core/CssBaseline';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { PageTitle } from "../constants/app-contants";
import Paper from '@material-ui/core/Paper';
import ReferenceBand from "../components/referenceBand";
import SampleStrips from "../components/sampleStrip";
import { TipsSheetComponent } from "../components/tips/TipsSheetComponent";
import SaveWarning from '../components/saveWarning';
import Typography from '@material-ui/core/Typography';
import { alpha } from "@material-ui/core/styles/colorManipulator";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    tip: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
        height: "300px",
      },
    paper: {
        padding: theme.spacing(2),
        elevation: "0",
        display: 'flex',
        overflow: 'auto',
        height: 900,
        // width: theme.spacing(80),
        background: "#FFFFFF"
      },
      container: {
        padding: 40,
        background: "#FFFFFF"
      },
    heading: {
        paddingTop: "20px",
        display: "flex",
        margin: theme.spacing(1)
    },
    button: {
      margin: theme.spacing(1),
      color: theme.palette.getContrastText("#2B78C2"),
      backgroundColor: "#2B78C2",
      height: "48px",
      paddingLeft: "30px",
      paddingRight: "30px",
      "&:hover": {
          backgroundColor: "#2B78C2",
          "@media (hover: none)": {
            backgroundColor: "#2B78C2"
          }
        }
  },
      reportButton: {
        color: "#1967D2",
        "&:hover": {
            backgroundColor: alpha("#1967D2", theme.palette.action.hoverOpacity),
            // Reset on touch devices, it doesn't add specificity
            "@media (hover: none)": {
            backgroundColor: "transparent"
            }
        },
        justifyContent: "flex-end",
        alignItems: "flex-end",
        alignSelf: "flex-end"
    },
    formControl: {
    margin: theme.spacing(0, 0, 0, 1),
  },

}));

const BatchValidity = ({ setState }) => {
    const dispatch = useDispatch();
    const classes = useStyles();

    const pageListLT = ['Upload', 'Enter Nikshay IDs', 'Check controls']
    const pageListMB = ['Check controls']

    const batchId = useSelector(state => state.globalActionsReducer.batch_id)?.batch_id
    const batchValidityData = useSelector(state => state.globalActionsReducer.batch_Validity_data)
    const [batchValidity, setBatchValidity] = React.useState(batchValidityData?.is_valid_batch ? 1 : 0);
    const [invalidBatchWarning, setInvalidBatchWarning] = React.useState(false);
    const batchType = batchValidityData?.batch_type;
    const [input, setInput] = React.useState([])
    const [output, setOutput] = React.useState([])
    const [showBackDialog, setShowBackDialog] = React.useState(false);

    // const batchId = 126;
    // useEffect(() => {
    //   dispatch(getBatchValidity(batchId));
    // }, [])

    const combineBandArrays = (interpreted, confidence) => {
      const bandData = []
      interpreted.forEach((obj, i) => {
        bandData.push({
          bandPresent: obj === 1,
          confidence: confidence[i]
        })
      })
      return bandData
    }

    useEffect(() => {
      if (batchValidityData.control_strips) {
        const inputData = batchValidityData.control_strips.map((row) => {
          const controlStrip = {
            sheetId: row.sheet_id,
            rowId: row.row_id,
            stripImagePath: row.strip_image_path,
            bandData: combineBandArrays(row?.reviewed_band_array ? row.reviewed_band_array : row.interpreted_band_array, row.interpreted_band_array.map(band => true)),
            stripType: row.strip_type,
            isValid: row.is_valid
          }
          return controlStrip;
        });
        setInput(inputData);
        setOutput(inputData);
      }
    }, [batchValidityData])

    if (!batchId) {
      setState("d")
      // history.push("dashboard")
    }

    function convertToArray (arr) {
      const outputArr = []
      arr.map(i => {
        outputArr.push(i.bandPresent ? 1 : 0)
        return true
      })
      return outputArr
    }

    function getInterpretationResults () {
      const updatedRows = []
      output.map(obj => {
        updatedRows.push({
          row_id: obj.rowId,
          reviewed_band_array: convertToArray(obj.bandData)
        })
        return true
      })
      if (batchValidity === 1) {
        dispatch(getInterpretation(batchId, updatedRows)).then(() => { setState("i") })
      } else {
        dispatch(getSummary(batchId, updatedRows, true)).then(() => { setState('s') })
      }
    }

    function handleSubmit () {
      if (batchValidity === 1) { getInterpretationResults() } else { setInvalidBatchWarning(true) }
    }

    function getRowIndex (sheetId, rowId) {
      return input.findIndex(row => row.sheetId === sheetId && row.rowId === rowId);
    }

    function checkControlStrip (stripType, outputRow) {
      if (stripType === "positive control") {
          if (outputRow[0].bandPresent && outputRow[1].bandPresent && outputRow[2].bandPresent) { return true } else { return false }
      } else if (stripType === "extraction control") {
          if (outputRow[0].bandPresent && outputRow[1].bandPresent && !outputRow[2].bandPresent) { return true } else { return false }
      } else if (stripType === "mastermix control") {
          if (outputRow[0].bandPresent && outputRow[1].bandPresent && !outputRow[2].bandPresent) { return true } else { return false }
      } else { return false }
    }

    const handleBandChange = (sheetId, rowId, j) => (event) => {
      const modifiedInput = [...input]
      const modifiedOutput = [...output]

      const i = getRowIndex(sheetId, rowId)
      modifiedInput[i].bandData[j].bandPresent = !modifiedInput[i]?.bandData[j]?.bandPresent

      if (checkControlStrip(modifiedOutput[i].stripType, modifiedOutput[i].bandData)) { modifiedOutput[i].isValid = true; } else { modifiedOutput[i].isValid = false }

      const modifiedBatchValidity = modifiedOutput.reduce((result, row) => result && row.isValid, true) ? 1 : 0

      setBatchValidity(modifiedBatchValidity)
      setInput([...modifiedInput]);
      setOutput([...modifiedOutput]);
    }

    function getValue (bool) {
      return bool ? 'Success:' : 'Failure:';
    }
    function getTextToDisplay (stripType, status) {
      if (stripType === 'positive control') {
        return status ? 'AC,CC and TUB bands are identified' : 'AC/CC/TUB not identified';
      } else {
        return status ? 'AC and CC bands are identified' : 'AC/CC band absent or presence of other bands';
      }
    }

    function getTag (text) {
      if (text === 'positive control') { return 'Positive Control' } else if (text === 'extraction control') { return 'Extraction Control' } else if (text === 'mastermix control') { return 'Mastermix Control' }
    }

    function handleBack () {
      dispatch(getBatchRoi(batchId)).then(() => {
        setState("m")
      })
    }
    function invalidBatchDialog () {
      return (
      <Dialog open={invalidBatchWarning} maxWidth={false}>
            <Paper elevation={0} style={{ width: "546px", height: "244px", display: "flex", marginTop: "28px", flexDirection: "column" }}>
            <Typography style={{ fontSize: "28px", fontWeight: "400", lineHeight: "18px", letterSpacing: "0.1px", alignItems: "center", paddingBottom: "10px", marginLeft: "24px" }} gutterBottom>
            This batch will not be interpreted
            </Typography>
            <Typography style={{ fontSize: "14px", fontWeight: "400", lineHeight: "24px", letterSpacing: "0.1px", alignItems: "center", paddingTop: "25px", marginLeft: "24px" }} gutterBottom>
            Since one or more of the controls failed, the batch in invalid and will not be interpretted. All samples in this batch will have to be retested.
            </Typography>
            <Divider variant="middle" style={{ marginTop: "28px", marginBottom: "28px" }}/>
            <DialogActions>
                <Button variant="outlined" style={{ width: "125px", borderColor: "#1967D2", float: "right", color: "#1967D2" }} onClick={() => setInvalidBatchWarning(false)} >Cancel</Button>
                <Button className={classes.button} style={{height: "36px"}} onClick={() => { setInvalidBatchWarning(false); getInterpretationResults(); }}>View Summary</Button>
            </DialogActions>
            </Paper>
      </Dialog>
      )
    }

    const [showHelpState, setshowHelpState] = React.useState(true);
    function handleShowHelp () {
      setshowHelpState(!showHelpState)
    }
    return (
      <React.Fragment>
          <CssBaseline />
            <AppBar />
            {invalidBatchDialog()}
            <SaveWarning changePage={handleBack} warningMessage={null} showDialog={showBackDialog} setShowDialog={setShowBackDialog}/>
            <TipsSheetComponent key={showHelpState} currentPageTitle={PageTitle.batchValidity}/>
            <Grid container style={{ background: "white" }} justifyContent="center">
            <BreadCrumbList pageList={batchValidityData?.mb_review ? pageListMB : pageListLT} setState={setState} warningMessage={null} />
                <Grid item xs={12} md={8} lg={9}>
                    <Paper variant="outlined" elevation={0} className={classes.paper} >
                      <Grid container>
                        {/* Check batch validity heading */}
                        <Grid container >
                          <Grid item>
                            <div className={classes.heading}>
                              {/* <ArrowBackIcon style={{ color: "#1A73E8", cursor: "pointer" }} onClick={() => history.push("/matchLabIds")}/> */}
                              {
                                batchValidityData?.mb_review === false &&
                              <ArrowBackIcon onClick={() => setShowBackDialog(true)} style={{ color: "#2B78C2", cursor: "pointer" }}></ArrowBackIcon>
                              }
                              <Typography style={{ color:"#202124", fontSize: "32px", fontWeight: "400", lineHeight: "24px", letterSpacing: "0.1px", paddingLeft: "15px" }} >
                              Batch is {batchValidity === 1 ? 'Valid' : 'Invalid'}
                              </Typography>
                              <HelpOutlineIcon onClick={handleShowHelp} style={{ marginLeft: "8px", cursor: "pointer", color: "#555770" }}></HelpOutlineIcon>
                            </div>
                          </Grid>
                        </Grid>

                        <Grid container>
                            <Grid item xs={5} md={5} lg={5}>
                            </Grid>
                            {/* Reference Band */}
                            <ReferenceBand batchType={batchType}/>
                        </Grid>
                        <Grid container >
                        {/* Control strips */}
                            <Grid item xs={5} md={5} lg={5}>
                              <Grid container >
                                {
                                  output?.map((row) => {
                                    return (
                                      <div style={{ height: '150px', width: "100%" }}>
                                      <Grid item xs={12} md={12} lg={12} style={{ height: '145px' }} >
                                        <Grid container style={{ paddingLeft: '100px' }}>
                                          <Grid item xs={12} md={12} lg={12} style={{ paddingTop: '20px' }}>
                                            {
                                              row.isValid
                                              ? <CheckCircleIcon style={{ color: "#48D292", fontSize: "35px" }} />
                                              : <CancelIcon style={{ color: "#FF5757", fontSize: "35px" }} />
                                            }
                                          </Grid>
                                          <Grid item xs={12} md={12} lg={12}>
                                            <Typography style={{ fontSize: "18px", fontWeight: "500", lineHeight: "24px", letterSpacing: "0.1px" }} >
                                              {getTag(row.stripType)}
                                            </Typography>
                                          </Grid>
                                          <Grid item xs={12} md={12} lg={12}>
                                            <Typography style={{ fontSize: "14px", fontWeight: "400", lineHeight: "22px", letterSpacing: "0.1px" }} >
                                              ({getValue(row?.isValid)} {getTextToDisplay(row?.stripType, row?.isValid)})
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                      <Divider />
                                      </div>

                                    )
}
                                  )
                                }
                              </Grid>
                            </Grid>
                        <SampleStrips input={input} batchType={batchType} handleBandChange={handleBandChange} />
                        </Grid>
                        <Grid container justifyContent="center" style={{ paddingTop: "20px" }}>
                          <Grid item>
                          <Button onClick={handleSubmit} className={classes.button} variant="contained" endIcon={<ArrowForwardIcon></ArrowForwardIcon>}> Review Interpretation </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Paper>
                </Grid>
            </Grid>
      </React.Fragment>
    );
}
export default BatchValidity;
