import qs from "query-string"

const fetchApi = (url, method = "GET", body = {}, headers = new Headers()) => {
    const obj = { method, headers, body }
    if (method === 'DELETE') {
        url = qs.stringifyUrl({ url: url, query: body });
        delete obj.body
    }

    if (method === 'GET') {
        delete obj.body
    }

    // console.log("request body", url, obj)
    return fetch(url, obj).then(res => {
    return res.json()
    }).catch(e => console.log("error", e))
}
export default fetchApi
