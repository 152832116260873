import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import EditIcon from '@material-ui/icons/Edit';
import Grid from '@material-ui/core/Grid';
import InterpretationTable from './interpretationTable';
import React, { useEffect } from 'react';
import Table from '@material-ui/core/TableBody';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import { getSummary } from '../actions/global';
import { sampleConditionsMapping } from '../env';
import { useDispatch } from 'react-redux'


const useStyles = makeStyles((theme) => ({
    button: {
        margin: theme.spacing(1),
        color: theme.palette.getContrastText("#2B78C2"),
        backgroundColor: "#2B78C2",
        height: "48px",
        paddingLeft: "30px",
        paddingRight: "30px",
        "&:hover": {
            backgroundColor: "#2B78C2",
            "@media (hover: none)": {
              backgroundColor: "#2B78C2"
            }
          }
    }
}))

export default function SampelInfoTable ({ allowEdit, sampleInfo, testValidity, batchType, batchId, batchValidity, reportHeaders, setReportData }) {
    const classes = useStyles();

    const dispatch = useDispatch();

    const [input, setInput] = React.useState([])
    const [output, setOutput] = React.useState([])
    const [interpretationEdit, setInterpretationEdit] = React.useState(false)
    const [inputToShow, setInputToShow] = React.useState()
    const [outputToShow, setOutputToShow] = React.useState()

    function toggleInterpretationEdit (sampleId) {
        if (sampleId) {
            if (!(interpretationEdit)) {
                setInputToShow(input.filter(row => row.rowId === sampleId))
                setOutputToShow(output.filter(row => row.rowId === sampleId))
            }
        }
        setInterpretationEdit(!interpretationEdit);
    }

    useEffect(() => {
        const inputArray = []
        const outputArray = []
        if (sampleInfo) {
            const combineBandArrays = (interpreted, confidence) => {
            const bandData = []
            interpreted.forEach((obj, i) => {
                bandData.push({
                bandPresent: obj === 1,
                confidence: confidence[i]
                })
            })
            return bandData
            }

            const combineResultArrays = (validity, genotypic, phenotypic) => {
            const resultData = [] // 11, 14
            const size = batchType === 'FL' ? 11 : 14;
            if (validity === 'INVALID_TEST') {
                resultData.push('INVALID TEST')
            } else if (validity === 'INDETERMINATE_TEST') {
                resultData.push('INDETERMINATE TEST')
            } else if (validity === 'MTB_NOT_DETECTED') {
                resultData.push('MTB NOT DETECTED')
            }
            if (resultData[0] === 'INVALID TEST' || resultData[0] === 'INDETERMINATE TEST' || resultData[0] === 'MTB NOT DETECTED') {
                [...Array(size - 1)].map(() =>
                resultData.push('')
                )
                return resultData
            } else {
                if (batchType === 'FL') {
                genotypic.map(obj => {
                    resultData.push(obj)
                    return true
                })
                phenotypic.map(obj => {
                    if (obj === 'S') {
                    resultData.push('S')
                    resultData.push('')
                    } else {
                    resultData.push('')
                    resultData.push('R')
                    }
                    return true
                })
                } else {
                genotypic.map(obj => {
                    resultData.push(obj)
                    return true
                })
                resultData.push(phenotypic[0])
                resultData.push(phenotypic[1])
                resultData.push('')
                resultData.push('')
                resultData.push(phenotypic[2])
                }
                return resultData
            }
            }

            sampleInfo.forEach(function (sample, index) {
                inputArray.push({
                    sheetNo: sample.sheet_no,
                    sheetId: sample.sheet_id,
                    rowId: sample.row_id,
                    stripImagePath: sample.strip_image_path,
                    bandData: combineBandArrays(sample?.reviewed_band_array ? sample.reviewed_band_array : sample.interpreted_band_array,
                    sample.band_confidence_array),
                    sampleId: sample.lab_sample_id,
                    position: sample.position
                })
                outputArray.push({
                    sheetId: sample.sheet_id,
                    rowId: sample?.row_id,
                    resultData: combineResultArrays(sample.test_validity, sample.genotypic_interpretation, sample.phenotypic_interpretation)
                })
                })

            setInput([...inputArray])
            setOutput([...outputArray])
    }
      }, [batchType, sampleInfo])

    function calcInterpretations (genotypicInterpretation, bandArray) {
        var result = []
        if (!(genotypicInterpretation?.[0]) || (!(bandArray?.[0]))) { return result }
        if (batchType === 'FL') {
            // RpoB-->Resistance to Rifampicin (RpoB)
            if (genotypicInterpretation[2] === '+') { result.push('Detected') } else if (genotypicInterpretation[1] === '+') { result.push('Not Detected') } else { result.push('Inferred') }

            // Katg--> High Level Resistance to Isoniazid
            if (genotypicInterpretation[4] === '+') { result.push('Detected') } else if (genotypicInterpretation[3] === '+') { result.push('Not Detected') } else { result.push('Inferred') }

            // inhA--> Low Level Resistance to Isoniazid
            if (genotypicInterpretation[6] === '+') { result.push('Detected') } else if (genotypicInterpretation[5] === '+') { result.push('Not Detected') } else { result.push('Inferred') }
        } else {
            // Resistance to Fluoroquinolones: (gyr A, gyr B)
            if (genotypicInterpretation[2] === '+' || genotypicInterpretation[4] === '+') { result.push('Detected') } else if (genotypicInterpretation[1] === '+' && genotypicInterpretation[3] === '+') { result.push('Not Detected') } else { result.push('Inferred') }

            // Resistance to Second Line Injectable Drugs: (Km, Am and Cm) (rrs)
            if (bandArray[20] === 1 || bandArray[21] === 1) { result.push('Detected') } else if (bandArray[18] === 1 && bandArray[19] === 1) { result.push('Resistance not detected') } else if (bandArray[18] === 0) { result.push('Inferred-1402') } else { result.push('Inferred') }

            // Katg--> High Level Resistance to Isoniazid
            if (genotypicInterpretation[8] === '+') { result.push('Detected') } else if (genotypicInterpretation[7] === '+') { result.push('Resistance not detected') } else { result.push('Inferred') }
        }
        result = result.concat(genotypicInterpretation);
        return result;
    }

    function convertToArray (arr) {
        const output = []
        arr.map(i => {
          output.push(i.bandPresent ? 1 : 0)
          return true
        })
        return output
    }
    function onSave (inputArray) {
        const updatedRows = []
        inputArray.map(obj => {
            updatedRows.push({
            row_id: obj.rowId,
            reviewed_band_array: convertToArray(obj.bandData)
            })
            return true
        })
        dispatch(getSummary(batchId, updatedRows, true)).then(toggleInterpretationEdit(null));
    }

    return (
    <>
    <Dialog maxWidth="" open={interpretationEdit} onClose={() => toggleInterpretationEdit(null)} >
    <div style={{ width: "100%", padding: "40px", height: "550px", overflow: "hidden" }}>

    <DialogTitle>
    <Typography style={{ fontSize: "28px", lineHeight: "18px", fontWeight: "400" }}>
    {inputToShow?.[0]?.sampleId}
    </Typography>
    </DialogTitle>
    <InterpretationTable batchType={batchType} input={input} output={output} setInput={setInput} setOutput={setOutput} inputToDisplay={inputToShow} outputToDisplay={outputToShow} />

    </div>
    <Grid style={{
                display: "flex",
                flexDirection: 'column',
                alignItems: "flex-end",
                justifyContent: "center",
                flex: 1,
                paddingTop: "20px",
                paddingRight: "20px"
            }}>
            <Button onClick={() => onSave(inputToShow)} className={classes.button}>
                                Save
            </Button>
    </Grid>
    </Dialog>
    <TableContainer >
    <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Table aria-label="customized table" >
            <TableHead style={{ backgroundColor: "#E5EDFF" }}>
            {
                (testValidity !== 'VALID_TEST' || !batchValidity)
                ? <TableRow>
                    <TableCell style={{ minWidth: '250px' }} align='center'>Nikshay ID</TableCell>
                    {
                    allowEdit === true &&
                    <TableCell style={{ minWidth: '250px' }}></TableCell>
                    }
                </TableRow>
                : batchType === 'FL'
                ? <TableRow>
                    <TableCell>Nikshay ID</TableCell>
                    <TableCell>Resistance to Rifampicin (RpoB)</TableCell>
                    <TableCell>High Level Resistance to Isoniazid</TableCell>
                    <TableCell>Low Level Resistance to Isoniazid</TableCell>
                    {
                    allowEdit === true &&
                    <TableCell></TableCell>
                    }
                </TableRow>
                : <TableRow>
                    <TableCell>Nikshay ID</TableCell>
                    <TableCell>Resistance to Fluoroquinolones: (gyr A, gyr B)</TableCell>
                    <TableCell>Resistance to Second Line Injectable Drugs: (Km, Am and Cm) (rrs)</TableCell>
                    <TableCell>Resistance to Second Line Injectable Drugs: (Low Level Km) (eis)</TableCell>
                    {
                    allowEdit === true &&
                    <TableCell></TableCell>
                    }
                </TableRow>
            }
            </TableHead>
            <TableBody>
            {sampleInfo.map((sample) => {
                var result = calcInterpretations(sample.genotypic_interpretation, sample.reviewed_band_array ? sample.reviewed_band_array : sample.interpreted_band_array);
                var reportSample = {};
                reportHeaders.forEach((header, i) => {
                    if (header.key === 'lab_sample_id')
                        reportSample['lab_sample_id'] = sample.lab_sample_id
                    else if (header.key === 'strip_condition')
                        reportSample['strip_condition'] = sampleConditionsMapping[batchType]?.[sample.strip_condition]
                    else
                        reportSample[header.key] = (testValidity !== 'VALID_TEST' || !batchValidity) ? 'NA' : result?.[i - 2];
                    
                })
                setReportData(prev => {
                    if (prev)
                    {
                        let index = prev.findIndex((sample) => sample.lab_sample_id === reportSample.lab_sample_id)
                        if (index === -1)
                            prev.push(reportSample);
                        else
                            prev[index] = reportSample;
                    }
                    else
                        prev = [reportSample]
                    return prev;
                    })
                return (
                <TableRow key={sample?.lab_sample_id}>
                <TableCell component="th" scope="row" align='center'>
                    {sample.lab_sample_id}
                </TableCell>
                {
                    (testValidity !== 'VALID_TEST' || !batchValidity)
                ? <></>
                : batchType === 'FL'
                ? <>
                    <TableCell align='center'>{result?.[0]}</TableCell>
                    <TableCell align='center'>{result?.[1]}</TableCell>
                    <TableCell align='center'>{result?.[2]}</TableCell>

                </>
                : <>
                    <TableCell align='center'>{result?.[0]}</TableCell>
                    <TableCell align='center'>{result?.[1]}</TableCell>
                    <TableCell align='center'>{result?.[2]}</TableCell>
                </>
                }
                {
                allowEdit === true &&
                <TableCell align='center'>
                    <Button onClick={() => toggleInterpretationEdit(sample.row_id)} style={{ color: "#FE7A10" }}>
                    <EditIcon style={{ color: "#FE7A10", fontSize: "13" }}/>
                    Edit
                    </Button>
                </TableCell>
                }
                </TableRow>
            )
})}
            </TableBody>
        </Table>
        </div>
        </TableContainer>
    </>

    )
}
