import React, { useEffect, useRef } from 'react';
import { getSheetsInBatch, submitLabIdsForBatch } from "../actions/global"
import { useDispatch, useSelector } from 'react-redux'

import AppBar from "../components/appBar"
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Autocomplete from "@material-ui/lab/Autocomplete";
import BreadCrumbList from '../components/breadCrumbList';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import ErrorDialog from '../components/errorDialog'
import Grid from '@material-ui/core/Grid';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import InputBase from '@material-ui/core/InputBase';
import { PageTitle } from "../constants/app-contants";
import Paper from '@material-ui/core/Paper';
import SaveWarning from '../components/saveWarning';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import { TipsSheetComponent } from "../components/tips/TipsSheetComponent";
import Typography from '@material-ui/core/Typography';
import { alpha } from "@material-ui/core/styles/colorManipulator";
import { logControlStripsAbsent } from "../analytics/analyticsHelper";
import { makeStyles } from '@material-ui/core/styles';

// import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
        height: 1250,
      },
      paper2: {
        // padding: theme.spacing(2),
        display: 'flex',
        // overflow: 'auto',
        height: 1030,
      },
      batchImageContainer: {
        height: 500,
        overflow: 'auto',
        paddingLeft: "30px",
        paddingRight: "30px"
      },
      container: {
        paddingLeft: 40,
        paddingTop: 20,
      },
      nextStepButton: {
          justifyContent: "center",
          display: "flex",
          paddingTop: "50px"
      },
      rightSideDiv: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: 280
    },
    c1: {
        paddingTop: 5
    },
    heading: {
        display: "flex",
        justifyContent: "flex-start",
    },
    button: {
        margin: theme.spacing(1),
        color: theme.palette.getContrastText("#2B78C2"),
        backgroundColor: "#2B78C2",
        minWidth: "179px",
        minHeight: "48px",
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "18px",
        letterSpacing: "0.1px",
        "&:hover": {
            backgroundColor: "#2B78C2",
            "@media (hover: none)": {
              backgroundColor: "#2B78C2"
            }
          }
    },
    reportButton: {
        color: "#1967D2",
        "&:hover": {
            backgroundColor: alpha("#1967D2", theme.palette.action.hoverOpacity),
            // Reset on touch devices, it doesn't add specificity
            "@media (hover: none)": {
            backgroundColor: "transparent"
            }
        }
    },
    nextButton: {
        margin: theme.spacing(2),
        color: theme.palette.getContrastText("#BDC1C6"),
        backgroundColor: "#BDC1C6",
        "&:hover": {
            backgroundColor: "#BDC1C6",
            "@media (hover: none)": {
              backgroundColor: "#BDC1C6"
            }
          }
    },
    dialogContent: {
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "22px",
        letterSpacing: "0.1px",
        color: "#3C4043"
    },
    margin: {
        margin: theme.spacing(0),
        width: "290px",
        padding: "0px",
        "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']": {
          padding: "0px"
        },
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
          borderColor: "white",
        },
        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
          borderColor: "white"
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderBottomColor: "#FFCC00"
        },
      },

}));
const noOfStrips = 24;
const CustomPaper = (props) => {
  return <Paper elevation={0} {...props} />;
};
// const labIds = new Array(totalSheets).fill(null).map(() => new Array(noOfStrips).fill(""));
const MatchLabIds = ({ setState }) => {
  const [imageheight, setImageHeight] = React.useState(0)
  const [imagewidth, setImageWidth] = React.useState(0)
  function getMeta (url, callback) {
    var img = new Image();
    img.src = url;
    img.onload = function () { callback(this.height, this.width); }
  }

  const dispatch = useDispatch();
  const classes = useStyles();
  // const history = useHistory();

  const pageList = ['Upload', 'Enter Nikshay IDs']

  const [drawLine, setDrawLine] = React.useState(-1);
  const [currentSheet, setCurrentSheet] = React.useState(0);
  const [erroDialogOpen, setErrorDialogOpen] = React.useState(false);
  const [showBackDialog, setShowBackDialog] = React.useState(false);
  const batchId = useSelector(state => state.globalActionsReducer.batch_id)?.batch_id
  if (!batchId) {
    setState("d")
    // history.push("dashboard")
  }
  const [errorHeading, setErrorHeading] = React.useState("Control strip missing  ");
  const [errorContent, setErrorContent] = React.useState("To check batch validity, you need to select Positive, Mastermix and Extraction controls");
  var batchRoi = useSelector(state => state.globalActionsReducer.batch_roi)?.sheets;
  // console.log("batchRoi", batchRoi?.length, batchRoi)
  const totalSheets = batchRoi ? batchRoi.length : 0;
  // const [labIds, setLabIds] = React.useState(new Array(totalSheets).fill(null).map(() => new Array(noOfStrips).fill("")));
  const labIdsInBatch = batchRoi.map(sheet => {
    return sheet?.lab_sample_id_list.map(labId => { return !labId ? "" : labId; });
  })
  const [labIds, setLabIds] = React.useState(labIdsInBatch);

  const handleErrorDialogOpen = () => {
    setErrorDialogOpen(true);
  };

  const handleErrorDialogClose = (value) => {
    setErrorDialogOpen(false);
  };

  function getOccurrence (array, value) {
    return array.filter((v) => (v === value)).length;
  }
  function areControlStripPresent () {
    const POSITIVE_CONTROL = "positive control"
    const MASTERMIX_CONTROL = "mastermix control"
    const EXTRACTION_CONTROL = "extraction control"
    var lowerCased = labIds.map(row => row.map(labId => labId.trim().toLowerCase()));
    var lowerCasedOneD = [].concat(...lowerCased)
    // console.log(lowerCasedOneD, new Set(lowerCasedOneD))
    const po = getOccurrence(lowerCasedOneD, POSITIVE_CONTROL);
    const mo = getOccurrence(lowerCasedOneD, MASTERMIX_CONTROL);
    const eo = getOccurrence(lowerCasedOneD, EXTRACTION_CONTROL);

    if (mo === 0 && eo === 0 && po === 0) {
      logControlStripsAbsent(mo, po, eo)
      setErrorHeading("Control Strip missing")
      setErrorContent("Positive, Extraction and Mastermix control strip missing. Please select it in order to process the batch")
      return false
    }

    if (po === 0 && eo === 0 && mo >= 1) {
      logControlStripsAbsent(mo, po, eo)
      setErrorHeading("Control Strip missing")
      setErrorContent("Positive and Extraction control strips missing. Please select it in order to process the batch")
      return false
    }
    if (po === 0 && mo === 0 && eo >= 1) {
      logControlStripsAbsent(mo, po, eo)
      setErrorHeading("Control Strip missing")
      setErrorContent("Positive and Mastermix control strip missing. Please select it in order to process the batch")
      return false
    }
    if (mo === 0 && eo === 0 && po >= 1) {
      logControlStripsAbsent(mo, po, eo)
      setErrorHeading("Control Strip missing")
      setErrorContent("Extraction and Mastermix control strip is missing. Please select it in order to process the batch")
      return false
    }

    if (po === 0 && eo >= 1 && mo >= 1) {
      logControlStripsAbsent(mo, po, eo)
      setErrorHeading("Control Strip missing")
      setErrorContent("Positive control strip is missing. Please select it in order to process the batch")
      return false
    }
    if (eo === 0 && po >= 1 && mo >= 1) {
      logControlStripsAbsent(mo, po, eo)
      setErrorHeading("Control Strip missing")
      setErrorContent("Extraction control strip is missing. Please select it in order to process the batch")
      return false
    }
    if (mo === 0 && eo >= 1 && po >= 1) {
      logControlStripsAbsent(mo, po, eo)
      setErrorHeading("Control Strip missing")
      setErrorContent("Mastermix control strip is missing. Please select it in order to process the batch")
      return false
    }

    if (po > 1) {
      logControlStripsAbsent(mo, po, eo)
      setErrorHeading("Repeated control strips")
      setErrorContent("Please enter only one positive control")
      return false
    }
    if (mo > 1) {
      logControlStripsAbsent(mo, po, eo)
      setErrorHeading("Repeated control strips")
      setErrorContent("Please enter only one mastermix control")
      return false
    }
    if (eo > 1) {
      logControlStripsAbsent(mo, po, eo)
      setErrorHeading("Repeated control strips")
      setErrorContent("Please enter only one extraction control")
      return false
    }
    var nonEmptyLowerCaseOneD = lowerCasedOneD.filter(val => val !== "")
    if (new Set(nonEmptyLowerCaseOneD).size !== nonEmptyLowerCaseOneD.length) {
      var repeatedIds = nonEmptyLowerCaseOneD.filter((id, i, arr) => arr.indexOf(id) !== i )
      setErrorHeading("Duplicate Lab Ids")
      setErrorContent("Lab " + (repeatedIds.length > 1 ? "IDs " : "ID ") + 
      repeatedIds.reduce((prev, curr, i, arr) => prev + String(curr) + (i < (arr.length - 1) ? ', ' : ''), '') + 
      (repeatedIds.length > 1 ? " are" : " is") + " repeated. Please enter distinct lab Ids")
      return false
    }
    return getOccurrence(lowerCasedOneD, POSITIVE_CONTROL) === 1 && getOccurrence(lowerCasedOneD, MASTERMIX_CONTROL) === 1 && getOccurrence(lowerCasedOneD, EXTRACTION_CONTROL) === 1
  }

  function handleBatchValidity () {
    var controlStripsPresent = areControlStripPresent();
    if (controlStripsPresent && totalSheets > 0) {
      var sheets = [];
      var lowerCased = labIds.map(row => row.map(labId => labId.trim().toLowerCase()));
      lowerCased.map((val, i) => { return sheets.push({ sheet_id: batchRoi[i]?.sheet_id, rows: val }) })
      dispatch(submitLabIdsForBatch(batchId, sheets)).then(() => { setState('b') })
    } else {
      handleErrorDialogOpen()
    }
  }
  // console.log(labIds)
  const canvas = useRef();
  const ctx = useRef();
  const options = ["Positive control", "Mastermix control", "Extraction control"];

  // console.log("labids", labIds);
  const drawLine1 = (info, style = {}) => {
    const { x, y, x1, y1 } = info;
    const { color = '#FFCC00', width = "4" } = style;

    ctx.current.beginPath();
    ctx.current.moveTo(x, y);
    ctx.current.lineTo(x1, y1);
    ctx.current.strokeStyle = color;
    ctx.current.lineWidth = width;
    ctx.current.stroke();
  }

  useEffect(() => {
    // dynamically assign the width and height to canvas
    if (totalSheets > 0) {
      const canvasEle = canvas.current;
      canvasEle.width = canvasEle?.clientWidth;
      canvasEle.height = canvasEle?.clientHeight;
      ctx.current = canvasEle.getContext("2d");
    }
  }, [drawLine, totalSheets]);

  useEffect(() => {
    if (drawLine > 0 && batchRoi.length > 0) {
        // getMeta(batchRoi[currentSheet]?.grid_image_location?.replaceAll("\\", "/"), function (height) { setImageHeight(height) })
        const absY = batchRoi[currentSheet]?.roi_for_rows[drawLine - 1]?.y - batchRoi[currentSheet]?.roi_for_grid_and_strip?.y + batchRoi[currentSheet]?.roi_for_rows[drawLine - 1]?.width
        // const yattr = (absY) * (canvas.current?.clientHeight / imageheight)
        // console.log("drawing line", drawLine, yattr, batchRoi[currentSheet])
        // drawLine1({ x: 5, y: yattr, x1: 500, y1: yattr });
        const originalimageheight = 1359;
        const originalimaagewidth = 1187;
        const currentimagewidth = canvas.current?.clientWidth
        const predheight = Math.min(1030, currentimagewidth * (originalimageheight / originalimaagewidth))

        // drawLine1({ x: 5, y: predheight, x1: 500, y1: predheight });

        const newyattr = predheight * (absY / originalimageheight)
        drawLine1({ x: 5, y: newyattr, x1: 500, y1: newyattr });
      }
  }, [batchRoi, currentSheet, drawLine, imageheight, imagewidth])

  useEffect(() => {
    if (batchRoi?.length > 0) {
        getMeta(batchRoi[currentSheet]?.grid_image_location?.replaceAll("\\", "/"), function (height, width) { setImageHeight(height); setImageWidth(width) })
      }
  }, [batchRoi, currentSheet, imageheight])

  useEffect(() => {
    const batchLabIds = batchRoi.map(sheet => {
      return sheet?.lab_sample_id_list.map(labId => { return !labId ? "" : labId; });
    })
    setLabIds(batchLabIds);
  }, [batchRoi])

  function previousSheetClick () {
    setCurrentSheet(currentSheet > 0 ? currentSheet - 1 : 0);
    setDrawLine(-1)
  }

  function nextSheetClick () {
    currentSheet === totalSheets - 1 ? handleBatchValidity() : setCurrentSheet(currentSheet + 1)
    setDrawLine(-1)
  }

  function handleBack () {
    dispatch(getSheetsInBatch(batchId)).then(() => {
      setState("u");
    })
  }
  const [showHelpState, setshowHelpState] = React.useState(true);
  function handleShowHelp () {
    setshowHelpState(!showHelpState)
  }
  return (
    <React.Fragment>
      <CssBaseline />
          <AppBar />

          <TipsSheetComponent key={showHelpState} currentPageTitle={PageTitle.matchLabIds}/>
          {totalSheets > 0
          ? <Grid container spacing={3} className={classes.container} style={{ background: "white" }}>
          <BreadCrumbList pageList={pageList} setState={setState} warningMessage={null} />
          <SaveWarning changePage={handleBack} warningMessage={null} showDialog={showBackDialog} setShowDialog={setShowBackDialog}/>
          <Grid item xs={12} md={4} lg={4}>
            {/* left side */}
            <Paper className={classes.paper} elevation={0} style={{ background: "#F8F9FA" }} >
            <div className={classes.heading}>
            {/* <ArrowBackIcon style={{ color: "#1A73E8", cursor: "pointer" }} onClick={() => setState("u")}/> */}
              <ArrowBackIcon onClick={() => setShowBackDialog(true)} style={{ color: "#1A73E8", cursor: "pointer" }}></ArrowBackIcon>
                <Typography style={{ fontSize: "32px", fontWeight: "400", lineHeight: "24px", letterSpacing: "0.1px", paddingLeft: "15px" }} gutterBottom>
                Enter Nikshay IDs
                </Typography>
                <HelpOutlineIcon onClick={handleShowHelp} style={{ marginLeft: "5px", cursor: "pointer" }}></HelpOutlineIcon>
                </div>
                <Typography variant="caption" display="block" style={{ color: "#BDC1C6", fontSize: "18px", fontWeight: "bold", lineHeight: "24px", letterSpacing: "0.1px", }} gutterBottom>
                    Sheet {currentSheet + 1}/{totalSheets}
                </Typography>
                {/* {getStepContent(currentSheet)} */}
                <TableContainer id={currentSheet} elevation={0} component={Paper} style={{ width: "400px" }} >
                <Table className={classes.table} size="small" stickyHeader padding="none" aria-label="simple table" >
                    <TableRow >
                        <TableCell align="center" style={{ width: "90px", borderRight: "1px solid #DADCE0" }}>
                          <Typography style={{ fontSize: "16px", fontWeight: "500", lineHeight: "24px", paddingTop: "5px", paddingBottom: "5px" }}>
                            Row. No.
                          </Typography>
                          </TableCell>
                        <TableCell align="left" style={{ paddingLeft: "14px" }}>
                        <Typography style={{ fontSize: "16px", fontWeight: "500", lineHeight: "24px", paddingTop: "5px", paddingBottom: "5px" }}>
                            Nikshay ID
                          </Typography>
                        </TableCell>
                    </TableRow>
                    <TableBody>
                    {[...Array(noOfStrips)].map((row, i) => (
                        <TableRow key={i}>
                        <TableCell align="center" scope="row" style={{ borderRight: "1px solid #DADCE0" }}>
                            {i + 1}
                        </TableCell>
                        <TableCell align="left" style={{ paddingLeft: "14px" }}>
                        <Autocomplete freeSolo
                        options={options}
                        inputValue={labIds[currentSheet][i]}
                        // value={labIds[currentSheet][i]}
                        onInputChange={(event, newValue) => {
                          labIds[currentSheet][i] = newValue;
                          setLabIds([...labIds]);
                        }}
                        PaperComponent={CustomPaper}
                        renderInput={(params) => (
                        <TextField
                            id={(currentSheet * i + i).toString() }
                            className={classes.margin}
                            variant="outlined"
                            InputProps={{ 'aria-label': 'naked', maxLength: 25, className: classes.margin }}
                            placeholder="Enter ID"
                            onClick={() => { setDrawLine(i + 1) }}
                            size="small"
                            controlled="true"
                            ref={params.InputProps.ref}
                            {...params}
                            onFocus={() => { setDrawLine(i + 1) }}
                        />
                        )}
                        />
                        </TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
                </TableContainer>
                <Typography style={{ display: "flex", justifyContent: "center", fontSize: "18px", fontWeight: "bold", lineHeight: "24px", letterSpacing: "0.1px", paddingTop: "22px" }} gutterBottom>
                {labIds[currentSheet].filter(Boolean).length} Lab IDs entered on this sheet  {Image.clientHeight}
                </Typography>
                <div style={{ display: "flex", justifyContent: "center" }}>
                {currentSheet === 0 ? ''
                : <Button disabled={currentSheet === 0}
                onClick= {previousSheetClick}
                className={classes.button} variant="contained" startIcon={<ArrowBackIcon></ArrowBackIcon>}> Previous Sheet
                </Button>}
                <Button
                onClick= {nextSheetClick}
                className={classes.button} variant="contained" endIcon={currentSheet === totalSheets - 1 ? '' : <ArrowForwardIcon></ArrowForwardIcon>}>
                {currentSheet === totalSheets - 1 ? 'Validate Batch' : 'Next sheet'}
                </Button>
                <ErrorDialog
                        open={erroDialogOpen}
                        onClose={handleErrorDialogClose}
                        errorHeading={errorHeading}
                        errorContent={errorContent}
                    />
                </div>
                <div style={{ display: "flex", justifyContent: "center" }} className={classes.nextStepButton}>
                {/* <Button onClick={handleBack} className={classes.button}
                        variant="contained" endIcon={<ArrowBackIcon></ArrowBackIcon>}>
                  Previous Page
                </Button> */}
                </div>
                </Paper>
            </Grid>
            {/* right side */}

            <Grid item xs={12} md={8} lg={8}>
              <Paper className={classes.paper2} variant="outlined" >
              <canvas ref={canvas} height="100%" width="830" style={{ height: "100%", width: "100%", backgroundImage: `url(${batchRoi[currentSheet]?.grid_image_location?.replaceAll("\\", "/")})`, backgroundRepeat: "no-repeat", backgroundSize: 'contain' }}/>
              </Paper>
            </Grid>
          </Grid>
          : ''}
    </React.Fragment>
  );
}

export default MatchLabIds;
